.basics-card {
    position: relative;
    width: 100%;
    height: 120px;
    border-radius: 4px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-items: start;
    cursor: pointer;
    &.active,
    &:hover {
        background: linear-gradient(0deg, rgba(152, 224, 95, 0.2) 0%, rgba(13, 227, 172, 0.19) 100%) !important;
        &:before {
            content: "";
            position: absolute;
            border: 2px solid #48d376;
            box-shadow: 0 6px 16px 0 rgba(4, 20, 42, 0.1);
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 4px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        width: 88px;
        height: 88px;
        right: 30px;
    }
    &.card-1 {
        background: #f0faf2;
        &:after {
            background: url("../images/basics_1.png") no-repeat center;
            background-size: cover;
        }
    }
    &.card-2 {
        background: #ebf6fa;
        &:after {
            background: url("../images/basics_2.png") no-repeat center;
            background-size: cover;
        }
    }
    &.card-3 {
        background: #f0faf2;
        &:after {
            background: url("../images/basics_3.png") no-repeat center;
            background-size: cover;
        }
    }
    &.card-4 {
        background: #f0fcff;
        &:after {
            background: url("../images/basics_4.png") no-repeat center;
            background-size: cover;
        }
    }
}


.room-timer-status {
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #ccc;
    font-weight: 700;
    cursor: pointer;
    &:last-child{
        margin-left: -1px;
    }
    &.active {
        background-color: #98c2ef;
        color: #fff;
        border-color:#98c2ef;
    }
    &.noactive {
        background-color: #e2e2e2;
        color: black;
        border-color:#e2e2e2;
        cursor: no-drop;
    }
}
.apartment-box{
    border:#e4e4e6 solid 1px;
    padding: 20px 30px;
    .talent-apartment-select{
        height: auto;
    }
    .ant-picker{
        background-color: #f0f4f6;
        border-radius: 6px;
        border-color:#f0f4f6;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color: #f0f4f6;
        border-radius: 6px;
        border-color:#f0f4f6;
    }
}
.talent-content{
    background: url("../images/baod/talent-bg.jpg") no-repeat bottom center;
    background-size: 100% auto;
}
