@import "themes/default";

/*招商引资*/
.result-title{
    position: relative;
    padding-left: 15px;
    font-size: 18px;
    color:@gray-one-color;
    margin: 20px 0;
    span{
        color:@primary-color;
        margin: 0 5px;
        font-size: 24px;
    }
    &:before{
        content:"";
        position: absolute;
        left:0;
        top:50%;
        transform: translateY(-50%);
        width: 4px;
        height: 16px;
        background-color: @primary-color;
        border-radius: 4px;
    }
}
.custom-tag{
    display: inline-block;
    background-color: #dedafc;
    color:@primary-color;
    padding: 0 8px;
    border-radius: 3px;
    margin: 0 5px 0 0;
    font-size: 12px;
}
.lineHeight2{
    line-height: 2 !important;
}
/*招商引资end*/


/*入口页面*/
.appeal-tel-item{
    height: 160px;
    background: url("../images/baod/appeal-bg.png") no-repeat center;
    background-size: cover;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
        background-color:  rgba(51, 153, 255, 0.2);
        .appeal-tel-icon{
            .icon{
                transform: rotateY(180deg);
            }
        }
    }
}

.workorder-tel-item{
    height: 160px;
    background-size: cover;
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.appeal-tel-icon{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    line-height: 80px;
    color:@white-color;
    background-color: rgba(51,153,255,0.3);
    margin-right: 25px;
    .icon{
        font-size: 32px;
        display: inline-block;
        transition: all 0.5s;
    }
}
.appeal-nav-item{
    background-color: rgba(51, 153, 255, 0.1);
    height: 160px;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
    &:hover{
        background-color:  rgba(51, 153, 255, 0.2);
        .appeal-tel-icon{
            .icon{
                transform: rotateY(180deg);
            }
        }
    }
}
/*入口页面end*/
