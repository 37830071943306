@import "themes/default";

.nav {
    background-color: @white-color;
    height: 84px;
    line-height: 28px;
    //color: @nav-color;
    font-size: 12px;
    position: relative;
    box-shadow: 0 6px 20px 0 rgba(35, 35, 35, 0.08);
    display: flex;
    align-items: center;
    transition: 0.2s all;
    .ant-badge-not-a-wrapper:not(.ant-badge-status) {
        vertical-align: middle !important;
    }
    .content-left {
        margin-top: 20px;
    }
    .title {
        font-size: 26px;
        font-weight: 500;
        color: #000000;
        margin-left: 18px;
    }
    .interval {
        margin: 0 7px 0 18px;
        font-size: 20px;
        line-height: 1;
        height: 20px;
        background: unset;
    }
    .pull-left {
        display: flex;
        align-items: center;
    }

    a {
        color: #333333;
        text-decoration: none;
        font-size: 14px;
        display: flex;
        vertical-align: bottom;
        align-items: center;
        justify-content: center;
        &:hover {
            color: @gray-four-color;
        }
    }
    &.new-nav{
        padding: 0 36px;
        height: 64px;
        background-color: transparent;
        position: absolute;
        left: 0;
        top:0;
        z-index: 100;
        width: 100%;
        border-bottom: rgba(255,255,255,0.1) solid 1px;
        min-width: 1220px;
        .container{
            max-width: 1000px;
            min-width: 1000px;
        }
        .ant-menu{
            background: none;
            border-bottom: none;
        }
        .index-header{
            justify-content: center;
        }
        .ant-menu{
            background-color: transparent;
            border-bottom: none;
            height: 60px;
            line-height: 60px;
            color:@white-color;
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item{
                margin-top: 0;
            }
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
                margin: 0 20px;
            }
            &.ant-menu-horizontal{
                > .ant-menu-item{
                    top:0;
                    border-bottom: transparent solid 1px;
                    margin: 0 30px;
                    a{
                        color:@white-color;
                        &::before{
                            bottom:0;
                        }
                        &:after{
                            content:"";
                            position: absolute;
                            left: 0;
                            bottom:0;
                            width: 0;
                            height: 5px;
                            background: linear-gradient(to right, #72def4 0% , #7759d1 100%);
                            transition: all 0.25s;
                        }
                    }
                }
                > .ant-menu-item-selected{
                    a{
                        &:after{
                            width: 100%;
                        }
                    }
                }
                .ant-menu-submenu-title{
                    &:after{
                        content:"";
                        position: absolute;
                        left: 0;
                        bottom:0;
                        width: 0;
                        height: 5px;
                        background: linear-gradient(to right, #72def4 0% , #7759d1 100%);
                        transition: all 0.25s;
                    }
                }
            }
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
                color:@white-color;
                border-bottom: transparent solid 1px;
                a{
                    &:after{
                        width: 100%;
                    }
                }
            }
            .ant-menu-item:hover,
            .ant-menu-item-active,
            .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
            .ant-menu-submenu-active,
            .ant-menu-submenu-title:hover{
                color:@white-color;
                .ant-menu-submenu-title{
                    &:after{
                        width: 100%;
                    }
                }
            }
            &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
                .ant-menu-submenu-title{
                    &:after{
                        width: 100%;
                    }
                }
            }
        }
        .content-right{
            color:@white-color;
            a{
                color:@white-color;
            }
        }
        .park-box{
            width: 220px;
            .ant-select{
                font-size: 18px;
                max-width: 100%;
            }
            
        }
      
        .ant-select,.ant-select-arrow{
            color:@white-color;
        }
        .index-header{
            margin-right: 30px;
        }
    }
}

.head-logo {
    // height: 45px;
    height: 32px;
    float: left;
    margin-right: 30px;

    img {
        height: 100%;
    }
}

.header {
    border-bottom: 1px solid #f0f0f0;
    background-color: @white-color;
    transition: all 0.2s;

    .ant-menu-horizontal {
        border-bottom: none;
        line-height: 54px;
    }
    .search-box {
        transition: all 0.3s;
        width: 160px;
        height: 30px;
        border-radius: 41px;
        &.active {
            width: 250px;
        }
        i {
            color: #000000;
        }
        .anticon {
            display: none;
        }
    }
    .container {
        .ant-menu {
            display: flex;
            width: 100%;
            .ant-menu-item {
                padding: 0;
                //margin: 0 25px;
                text-align: center;
                flex: 1;
                border: none !important;
                > a {
                    color: #000000;
                }
            }
            .ant-menu-submenu {
                padding: 0 25px;
                margin: 0;
                border: none;
                flex: 1;
                text-align: center;
                &::before {
                    content: "\E680";
                    position: absolute;
                    right: 40px;
                    color: #000;
                    font-family: "icon" !important;
                }
                span {
                    color: #000000;
                }
            }
            .ant-menu-submenu {
                border: none !important;
            }
        }
    }

    .ant-menu {
        li {
            float: right;
            &.ant-menu-item-selected,
            &.ant-menu-submenu-selected,
            &.ant-menu-submenu-active {
                &::after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 4px;
                    background-color: @primary-color;
                    border-radius: 4px;
                    bottom: 6px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &:hover {
                & > a {
                    color: #000000 !important;
                }

                .ant-menu-submenu-title {
                    color: #000000 !important;
                    border: none;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 4px;
                    background-color: @primary-color;
                    border-radius: 4px;
                    bottom: 6px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

.global-search {
    width: 250px;

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 62px;
    }

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
        right: 0;
    }

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .global-search-item-count {
        position: absolute;
        right: 16px;
    }
}
.ipark-menu{
    .ant-menu-vertical.ant-menu-sub{
        min-width: unset !important;
        //margin-left: 40px;
        margin-left: -15px;
        .ant-menu-item{
            font-size: 16px;
        }
    }
}

.head-menu {
    z-index: 999;
    position: relative;
    &.hide{
        .Affix{
            position: fixed;
            width: 100%;
            z-index: 9;
        }
        .ant-affix{
            height: auto !important;
        }
        .header {
            box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
        }
        .nav{
            margin-top: -83px;
        }
    }

}

.head-menu {
    position: relative;
    overflow: hidden;
    z-index: 100;
}

.header-search {
    height: 32px;
    line-height: 32px;
    padding-right: 20px;
    // &:hover {
    //   padding-left: 180px;
    //  input{
    //   display: inline-block;
    //   // background-color: @primary-color;
    //   opacity: 1;
    //   width: 180px;
    //  }
    // }
    input {
        width: 85%;
        // border: none;
        padding-right: 30px;
        // background-color: rgba(0, 0, 0, 0);
        // transition: all .3s ease-in-out;
        // border-radius: 10px;
        // opacity: 0;
    }

    i {
        font-size: 20px;
        margin-top: 6px;
        float: right;
        color: @primary-color;
        cursor: pointer;
    }
}
.gutter-example {
    .ant-menu-inline .ant-menu-item {
        font-size: 16px;
        color: #333;
        height: 64px;
        line-height: 64px;
    }
    .ant-menu-inline .ant-menu-item-selected {
        color: #fff;
        margin: 0 -10px;
        border-radius: 6px;
        box-shadow: 0 6px 8px 0 rgba(0, 106, 241, 0.36);
        width: calc(~"100% + 20px");
    }
}
.home-search {
    width: 218px;
    height: 30px;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    position: relative;
    padding-right: 64px;
    margin-left: 15px;
    background-color: rgba(255,255,255,0.1);
    input {
        border: none;
        outline: none;
        box-shadow: unset !important;
        background-color: transparent;
        color:@white-color;
    }
    a {
        width: 30px;
        height: 30px;
        background-color: #6d74ff;
        border-radius: 15px;
        position: absolute;
        text-align: center;
        color: #ffffff;
        right: -1px;
        top: -1px;
    }
}
.content-right {
    display: flex;
}

.ant-affix{
    .nav.new-nav{
        background-color: rgba(0,0,0,0.4);
    }
}

@media (max-width: 1700px) {
    .nav.new-nav{
        padding: 0 30px;
        .container{
            max-width: 860px;
            min-width: 860px;
        }
    }
    .home-search{
        width: 150px;
    }
    .nav.new-nav .index-header{
        margin-right: 0;
    }
}
@media (max-width: 1600px) {
    .nav.new-nav{
        padding: 0 20px;
        .container{
            max-width: 700px;
            min-width: 700px;
        }
    }
    .nav.new-nav .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .nav.new-nav .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
        margin: 0 10px;
    }
}
@media (max-width: 1410px) {
    .nav.new-nav .park-box{
        width: 150px;
    }
    .home-search{
        width: 140px;
    }
    .nav.new-nav{
        padding: 0 15px;
        .container{
            max-width: 600px;
            min-width: 600px;
        }
    }
}
@media (max-width: 1300px) {
    .nav.new-nav{
        padding: 0 15px;
        .container{
            max-width: 520px;
            min-width: 520px;
        }
    }
}
