/*党建*/
.party-breadcrumb {
    color: #ffffff;
    text-align: right;
    .ant-breadcrumb-link {
        cursor: pointer;
    }
    &.ant-breadcrumb {
        margin-bottom: 10px;
    }
    span {
        color: #ffffff;
    }
}
.party-content {
    position: relative;
    background: url("../../assets/images/party/party.png") no-repeat;
    width: 100%;
    margin: 0 auto;
    background-size: 100%;
    min-height: 1500px;
    * {
        z-index: 2;
    }
    &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 11),
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 1),
            #ffffff
        );
    }

    & > .title {
        font-size: 36px;
        text-align: center;
        color: #ffffff;
        padding: 60px 0;
        font-weight: 400;
    }
    .ant-card {
        border-radius: 0;
        .ant-card-head {
            border: none;
            font-size: 24px;
            .ant-card-head-title {
                padding-left: 30px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    height: 22px;
                    width: 9px;
                    left: 10px;
                    top: 32%;
                    background-image: linear-gradient(to bottom, #ff1901, #ff8c05);
                    border-radius: 90px;
                }
            }
            .more {
                color: #ff3600;
                font-size: 16px;
            }
        }
    }
}
.notice-box {
    .ant-card-body {
        padding: 0 16px 16px 32px;
    }
    .item {
        padding: 12px;
        position: relative;
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            background-color: rgba(255, 119, 19, 1);
            height: 1px;
            bottom: 0;
            left: 0;
            transition: 1s;
        }
        &:hover {
            background-color: rgba(245, 154, 35, 0.1);
            &:after {
                width: 100%;
            }
        }
    }
    .date {
        color: #fff;
        height: 60px;
        width: 60px;
        background-image: linear-gradient(to right bottom, #ff7814, rgba(255, 120, 20, 0.3));
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px;
        margin-right: 12px;
    }
}

.party-card {
    background-color: #ffffff;
}
.swiper-box {
    height: 280px;
}
.party-card {
    font-size: 24px;
    .ant-card-body {
        padding: 0;
    }
    .title {
        position: relative;
        padding-left: 30px;
        padding-bottom: 6px;
        font-weight: 500;
        .more {
            color: #ff3600;
            font-size: 16px;
            font-weight: 400;
        }
        &:before {
            content: "";
            position: absolute;
            height: 22px;
            width: 9px;
            left: 10px;
            top: 18%;
            background-image: linear-gradient(to bottom, #ff1901, #ff8c05);
            border-radius: 90px;
        }
    }
    .swiper-box {
        // cursor: pointer;
        .swiper-slide {
            padding: 10px;
            &:before {
                content: "";
                position: absolute;
                width: 0;
                background-color: rgba(255, 119, 19, 1);
                height: 1px;
                bottom: 0;
                left: 0;
                transition: 1s;
            }
            &:hover {
                background-color: rgba(245, 154, 35, 0.1);
                &:before {
                    width: 100%;
                }
            }
        }
    }
}
.party-apply {
    padding: 20px;
    color: #ffffff;
    height: 100px;
    background-image: linear-gradient(to right bottom, #ff5958, #ff9706);
    .title {
        font-size: 18px;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 6px;
    }
    i {
        font-size: 75px;
        opacity: 0.3;
        position: absolute;
        right: 20px;
        top: 0;
    }
    span {
        opacity: 0.6;
        cursor: pointer;
    }
}

// 党建菜单
.party-menu {
    &.ant-menu-inline {
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05) !important;
        border: none;
        .ant-menu-item {
            font-size: 16px;
            color: #333;
            height: 64px;
            line-height: 64px;
            text-align: center;
            &.ant-menu-item-selected {
                background-image: linear-gradient(to right, #ff5958, #ff9706);
                color: #fff;
            }
            &:after {
                border-right: 3px solid #ff9706;
            }
        }
    }
    .menu-title {
        background-color: #ffffff;
        font-weight: 500;
        font-style: normal;
        font-size: 20px;
        text-align: center;
        color: #ff1400;
        height: 64px;
        line-height: 64px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
}

.party-list {
    .ant-pagination {
        text-align: center;
    }
}

.dynamic-text {
    background-color: #ffffff;
    padding: 80px;
    position: relative;
    .title {
        font-size: 28px;
        color: #2a569a;
        text-align: center;
        padding-bottom: 10px;
    }
    .desc {
        color: #999999;
        padding: 10px 0;
        border-bottom: #2a569a solid 1px;
        span {
            margin-right: 30px;
        }
    }
}
