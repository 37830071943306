@import "themes/default";
.footer{
  color: @gray-one-color;
  .ant-typography{
    color: #ffffff;
  }
  .footer-desc{
    margin-top: 10px;
    font-size: 14px;
    div{
      margin: 5px 0;
    }
  }
  .footer-content{
     margin-right: 30px;
     ul,li{
       margin: 0;
       padding: 0 0 5px;
       list-style: none;
     }
     a{
        color: @foot-link-color;
       &:hover{
         color: @foot-link-color-hover
       }
     }
  }
  .ant-col:last-child{
    .footer-content{
      margin-right: 0;
    }
  }
  .footer-desc{
    p{
      margin-bottom: 0;
      i{
        margin-right: 3px;
        &.icon-phone{
          margin-right: 6px;
        }
      }
    }
    padding-bottom: 15px;
  }
  .container{
    max-width: 1400px;
    min-width: 1400px;
  }
}
.copy-right{
  background-color: #e6eaff;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  color:#999;
  span{
    margin: 0 25px;
  }
}
.footer-top{
  background-color: #f7f9ff;
  padding: 20px 0 15px;
}
.footer-logo{
  text-align: center;
  img{
    max-width: 100%;
  }
}

@media (max-width: 1400px) {
  .footer .container{
    max-width: 1200px;
    min-width: 1200px;
  }
}
