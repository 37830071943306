@charset "UTF-8";

/*list-box*/
.list-box {
    .ant-card {
        border-radius: 4px;
        position: relative;
        border: 1px solid #f0f0f0;
        .images-auto {
            border-radius: 4px 4px 0 0;
        }
        &:hover {
            .title {
                color: #096dd9 !important;
            }
            .list-title {
                .ant-typography {
                    color: #096dd9;
                }
            }
        }
    }
    .ant-card-small > .ant-card-body {
        border-radius: 4px;
    }
    div.ant-typography {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .ant-list-item {
        padding: 8px 0;
    }

    .list-title {
        .ant-typography {
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 20px !important;
        }

        .ant-typography a {
            color: @gray-one-color;

            &:hover {
                color: @primary-color;
            }
        }
    }

    .list-more {
        margin-top: 10px;
    }
}
.price-img {
    position: relative;
    i {
        // background-color: @primary-color;
        background-color: #2289cb;
        color: @white-color;
        line-height: 1;
        padding: 8px 10px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        font-style: normal;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.index-price-img {
    position: relative;
    i {
        background-color: @primary-color;
        color: @white-color;
        width: 64px;
        height: 64px;
        text-align: center;
        line-height: 64px;
        font-size: 16px;
        position: absolute;
        left: -8px;
        top: -8px;
        z-index: 1;
        font-style: normal;
        &.type1 {
            background-color: #ff9a1b;
        }
        &.type2 {
            background-color: #2289cb;
        }
        &.type3 {
            background-color: #c9c9c9;
        }
    }
}
.price-img-mini {
    i {
        font-size: 12px;
        line-height: 1;
        padding: 5px;
    }
}

/*list-box end*/

/*list-box1*/
.list-box1 {
    .ant-card {
        border-radius: 4px;
        position: relative;
        border: 1px solid #f0f0f0;
        width: 100%;
        .images-auto {
            border-radius: 4px 4px 0 0;
        }
        &:hover {
            .title {
                color: #096dd9 !important;
            }
        }
    }
    .ant-card-body {
        border-radius: 4px;
    }
    .word {
        padding: 10px 15px;
    }

    .title {
        font-size: 18px;
        color: #000000;
    }

    .icon {
        margin-right: 4px;
    }

    .ant-card-body {
        background-color: @white-color;
    }

    div.ant-typography {
        margin-bottom: 0;
    }
    .ant-list-item {
        padding: 8px 0;
    }

    .primary {
        color: @primary-color;
    }
}
/*list-box1 end*/

/*list-horizontal*/
.ant-list.list-horizontal {
    background-color: @white-color;
    border-radius: 2px;

    .ant-list-item {
        padding: 16px;
    }

    .ant-list-item-meta-content {
        max-width: 100%;
    }

    .ant-list-item-meta {
        margin-bottom: 0;
    }

    .ant-list-item-meta-title {
        // font-size: 18px;

        a {
            color: @gray-one-color;

            &:hover {
                color: @primary-color;
            }
        }
    }

    .ant-list-item-action {
        margin-top: 10px;
    }

    div.ant-typography,
    .ant-typography p {
        margin-bottom: 0;
    }
}

.info-icon {
    cursor: default;

    .icon {
        margin-right: 5px;
        font-size: 14px;
    }
}

/*list-horizontal end*/
.list-layout{
    .ant-list-item-meta{
        align-items: stretch;
        .ant-list-item-meta-description{
            height: 100%;
            .list-container{
                width: 100%;
                height: 100%;
                overflow: hidden;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                .title{
                    font-size: 18px;
                    color: rgba(0, 0, 0, 0.85) ;
                    font-weight: 500;
                    &:hover{
                        color: @link-color;
                    }

                }
                .list-body{
                    overflow-x: hidden;
                    overflow-y: auto;
                    -webkit-flex: 1;
                    -ms-flex: 1;
                    flex: 1;
                    -webkit-overflow-scrolling: touch;
                }
            }
        }
    }
}

/*list-basic*/
.ant-list.list-basic {
    .ant-list-header {
        padding: 0;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        margin-bottom: 5px;
        border-bottom: none;
    }

    .ant-list-item {
        border-bottom: none;
        padding: 5px 0;
    }

    div.ant-typography,
    .ant-typography p {
        margin-bottom: 0;
    }

    .ant-list-item-meta,
    .ant-list-item-meta-content {
        max-width: 100%;
    }

    .ant-list-item-meta-title {
        a {
            color: rgba(0, 0, 0, 0.85);

            &:hover {
                color: @primary-color;
            }
        }

        i {
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background-color: #f0f0f0;
            color: @text;
            font-size: 12px;
            font-style: normal;
            margin-right: 12px;

            &.bg-color1 {
                background-color: @primary-color;
                color: @white-color;
            }
        }
    }
}

/*list-basic end*/

/*list-commonly*/
.list-commonly {
    .ant-list-item {
        background-color: #fff;
    }

    .ant-list-header {
        background-color: #fff;
    }
    .ant-comment {
        &:last-child {
            border-bottom: none;
        }
    }
}
.hover-link {
    .ant-typography {
        color: @gray-one-color!important;

        &:hover {
            color: @primary-color!important;
        }
    }
}

.hot-title {
    font-size: 18px;
    line-height: 30px;
}

/*list-commonly end*/

/*list-loose*/
.list-loose {
    position: relative;

    .ant-typography {
        // &:first-child {
        //     margin-top: 10px;
        // }

        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 115px;
        padding: 2.5px 0;
        text-align: center;
    }
}

/*list-loose end*/
/*list-loose2*/
.list-loose2 {
    .ant-typography {
        margin-bottom: 0;
    }
}

/*list-loose2 end*/
/*list-loose3*/
.list-loose3 {
    .ant-list-item-meta-title {
        .ant-typography {
            font-size: 18px;
            // margin-bottom: 0.7rem !important;
        }
    }

    div.ant-typography,
    .ant-typography p {
        margin-bottom: 0.3rem;
    }
}

/*list-loose3 end*/
/*list-loose4*/
.list-reminder {
    .ant-list-item-meta {
        margin-bottom: 0 !important;

        i {
            color: #ff9a06;
        }
    }

    .ant-list-item-action {
        margin-top: 0 !important;
    }

    // h4 {
    //     font-size: 14px !important;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     max-width: 240px;
    //     margin-bottom: 0 !important;
    // }
}

/*list-loose4 end*/
/*list-compact*/
.list-compact {
    div {
        .ant-list-item-meta {
            margin-bottom: 0;
        }
        .ant-list-item-action {
            margin-top: 5px;

            li {
                cursor: auto;
            }
        }
    }

    .ant-list-item-meta-content {
        width: 100%;

        h4 {
            overflow: hidden;
            white-space: nowrap;
            display: block;
            width: 100%;
            text-overflow: ellipsis;
        }
    }

    div.ant-typography,
    .ant-typography p {
        margin-bottom: 0;
    }
}

/*list-compact*/

/*list-title-down*/
.list-title-down {
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }
    h4 {
        .list-down-title {
            width: 100%;
            position: relative;
            overflow: hidden;
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;

            a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 360px;
                color: rgba(0, 0, 0, 0.85);
                display: block;
                float: left;

                &:hover {
                    color: @primary-color;
                }
            }

            i {
                font-size: 12px;
                color: @link-color;
                float: right;
                margin-top: 5px;
            }
        }
    }
}

/*list-title-down end*/
/*list-commonly-small*/
.list-commonly-small {
    background-color: #fff;
    .title {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.45);
        &:hover {
            color: @primary-color;
        }
    }
    &.ant-list-vertical .ant-list-item-action {
        margin-top: 10px;
    }
}
/*list-commonly-small end*/

/*list-table*/
.list-table {
    .ant-table {
        background-color: @white-color;
        border: #e8e8e8 solid 1px;
    }

    .ant-table-thead > tr > th {
        text-align: center;
    }

    .ant-table-tbody > tr:last-child > td {
        border-bottom: none;
    }

    .ant-table-pagination.ant-pagination {
        text-align: center;
        float: none;
        margin: 50px 0 80px;
    }

    .name {
        color: @gray-one-color;

        &:hover {
            color: @primary-color;
        }
    }
}

/*list-table end*/

/*list-details*/
.list-details {
    .ant-col {
        padding: 10px 0;

        .doc-pdf {
            a {
                display: block;
                padding: 5px 10px;
                background-color: #f5f5f5;
                margin-bottom: 10px;
            }
        }
    }
}

/*list-details end*/
/*list-comment*/
.list-comment {
    .ant-comment-content-author-name {
        color: @primary-color;
    }

    .ant-comment-content-author-time {
        color: @text;
    }

    .ant-list-split .ant-list-header {
        position: relative;
        margin-bottom: 30px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            height: 2px;
            width: 50px;
            background-color: @primary-color;
            border-radius: 2px;
        }
    }

    .ant-form-item-control {
        text-align: right;
    }

    .ant-btn {
        margin-left: 10px;
    }

    .ant-form-item {
        margin-bottom: 12px;
    }
}
/*list-comment end*/
.ant-modal-body {
    .ant-form-item {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*list-relative*/
.iteam-relative-list {
    .ant-list-item {
        position: relative;
        display: block;
    }
}
/*list-relative-end*/

/* information-list*/
.information {
    .ant-card-body {
        padding: 20px;
    }
}

.information-infos {
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 24px;

    .ant-avatar {
        margin-bottom: 8px;
    }

    .information-name {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }
}

.information-detail {
    div {
        i {
            margin-right: 10px;
        }

        span {
            display: inline-block;
            width: 190px;
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*不换行*/
            text-overflow: ellipsis; /*超出部分文字以...显示*/
        }

        span:hover {
            text-overflow: inherit;
            overflow: visible;
            white-space: pre-line; /*合并空白符序列，但是保留换行符。*/
        }

        em {
            font-style: normal;
            color: #c8c8c8;
        }
    }
}

/* information-list -end*/

/*栅格列表 grid-card*/
.grid-card {
    .ant-card-head {
        background-color: #f2f2f2;
        color: @gray-two-color;
        font-weight: normal;
    }
    .grid-card-list {
        margin: 5px 0;
        min-height: 22px;
    }
    .type-name {
        width: 70px;
    }
    .grid-name {
        width: 85px;
        display: inline-block;
        color: @text;
        margin-right: 10px;
    }
    .ant-btn {
        margin-left: 5px;
    }
}
/*栅格列表end*/

.list-hover{
    .ant-list-split{
        .ant-list-item{
            padding: 16px;
            &:hover{
                background-color: #fafafa;
            }
        }
    }
}




