@charset "UTF-8";
@import "themes/default";

/*首页走马灯*/

.home-box {
    background-size: cover;
    position: relative;

    .ant-carousel {
        height: 100%;
        width: 100%;
        .slick-dots{
            align-items: center;
            li{
                width: 10px;
                vertical-align: top;
                height: 12px;
                margin: 0 4px;
                button{
                    background: url("../images/baod/dot-active.png") no-repeat;
                    background-size: cover;
                    width:10px;
                    height: 12px;
                }
                &.slick-active{
                    width: 12px;
                    height: 14px;
                    button{
                        width: 12px;
                        height: 14px;
                        background: url("../images/baod/dot-active.png") no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }

    .ant-carousel > div {
        height: 100%;
        width: 100%;
    }

    .slick-slider > div {
        height: 100%;
        width: 100%;
    }

    .slick-list > div {
        height: 100%;
        width: 100%;
    }

    .slick-slide {
        height: 680px !important;
        width: 100%;

        & > div {
            height: 100%;
            width: 100%;

            .home-bkg {
                height: 100%;
                width: 100%;

                .home-img-box {
                    float: left;
                    width: 100%;
                    background-position: center;
                    display: table;
                    height: 100%;
                    background-size: cover !important;
                }

                .home-inner {
                    height: 100%;

                    color: #fff;
                    padding-top: 40px;

                    vertical-align: middle;
                    background-color: rgba(0, 0, 0, 0.4);

                    .home-content {
                        max-width: 1100px;
                        margin-left: auto;
                        margin-right: auto;

                        .home-title {
                            padding: 0;
                            font-size: 42px;
                            line-height: 1.4em;
                            text-align: left;
                            color: #fff;
                        }

                        .home-sub-text {
                            padding: 5px;
                            font-weight: 300;
                            line-height: 1.8em;
                            color: rgba(255, 255, 255, 0.8);
                            text-align: left;
                            font-size: 32px;
                            color: #3aa4e8;
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }

    .home-tab {
        height: 553px;

        //.ant-tabs-nav-scroll {
        //  text-align: center;
        //}
    }
}

.home-sider-img-box {
    position: relative;
    height: 217px;
    margin: 15px;
    overflow: hidden;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
        .home-sider-tit-content {
            bottom: 0;
        }

        .home-sider-img-tit {
            transform: translateY(100%);
        }
    }
}

.home-sider-img-tit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    line-height: 44px;
    padding: 0 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    transition: all 0.2s;
}

.home-sider-tit-content {
    position: absolute;
    bottom: -100%;
    height: 100%;
    width: 100%;
    padding: 5%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.5s;

    .home-sider-tit1 {
        font-size: 18px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .home-sider-tit-p {
        font-size: 13px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

/*首页走马灯-end*/

/*首页*/
.home-navigation {
    position: absolute;
    height: 100%;
    width: 10%;
    // top: 10%;
    z-index: 9;
    overflow: hidden;
}
.home-notice {
    .ant-tag {
        width: 71px;
        height: 32px;
        font-size: 14px;
        border-radius: 4px;
        line-height: 32px;
        text-align: center;
        color: #666666;
        background: rgba(238, 238, 238, 1) !important;
    }
}
.home-content {
    .ant-btn {
        // width: 220px;
        &:hover {
            color: #fff;
            background-color: @primary-color;
            border-color: @primary-color;
        }
    }
}

.home-text-content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    width: 100%;
    t-card {
        border: 1px solid rgba(229, 229, 229, 1);
    }
    .index-price-img {
        .images-auto {
            border-radius: 0;
        }
    }
}
.index-activity-card {
    border: 1px solid rgba(229, 229, 229, 1);
    &:hover {
        .title {
            color: @primary-color !important;
        }
    }
    &.ant-card-small > .ant-card-body {
        padding: 21px 18px;
    }
}
.home-arrow {
    display: block;
    height: 100%;
    width: 90%;
    transition: all 0.2s ease-out;
}

.home-img-prev {
    left: 0;

    &:hover {
        .home-arrow {
            opacity: 0.5;
            margin: 0px 0px 0px 20%;
            cursor: pointer;
        }
    }

    .home-arrow {
        opacity: 0;
        margin: 0px 0px 0px -20%;
        font-size: 50px;
        color: #fff;
        display: flex;
        align-items: center;
    }
}

.home-img-next {
    right: 0;

    &:hover {
        .home-arrow {
            opacity: 0.5;
            margin: 0 0 0 20%;
            cursor: pointer;
        }
    }

    .home-arrow {
        opacity: 0;
        margin: 0 0 0 70%;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 50px;
        justify-content: center;
    }
}

.home-header {
    .home-description {
        font-size: 12px;
        line-height: 2;
        color: #9b9b9b;
        text-align: center;
        margin: 13px 0 0;
    }
}

.index-container {
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;

    .home-item {
        background-color: #fff;
        box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        margin: 15px;

        .home-activity-set {
            position: relative;
            height: 217px;
            margin-bottom: 2rem;
            overflow: hidden;
            cursor: pointer;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

.home-features-title {
    font-size: 16px;
    color: #262626;
    margin: 8px 0 -10px;
    font-weight: bold;
}

.home-new {
    .newtime {
        font-size: 12px;
        position: relative;
        margin: 0;
        padding-left: 15px;

        span {
            background: #cccccc;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            position: absolute;
            left: -4px;
            top: 5px;
        }

        label {
            font-weight: normal;
            margin: 0 2% 0 2%;
            font-size: 14px;
            display: inline-block;
        }
    }

    .box-bor {
        border-left: 2px solid #cccccc;
        padding-left: 15px;
        margin-top: -6px;
        padding-top: 15px;

        .home-news-title {
            color: #333333;
            font-size: 18px;
        }

        .home-news-text {
            color: #9e9e9e;
            font-size: 14px;
        }
    }
}

.home-notice {
    position: relative;
    overflow: hidden;
    height: 64px;
    line-height: 64px;
    background-color: #f9f9f9;

    .home-notice-swiper {
        height: 64px;
        width: 100%;
        .swiper-slide {
            text-align: left;
            line-height: 64px;
            font-size: 16px;
        }
    }
}

.home-body {
    position: relative;
    background-color: #fff;
    .home-text-content{
        max-width: 1400px;
    }
}

.home-content {
    position: relative;
    overflow: hidden;
    margin-top: 48px;
}

.homt-title {
    position: relative;
    overflow: hidden;
    text-align: center;
    font-size: 36px;
    color: @gray-one-color;
    line-height: 34px;
    padding: 30px 0 30px;
    margin-bottom: 40px;
}

.home-service-list {
    position: relative;
}

.home-service-row {
    position: relative;
    display: flex;
    align-items: center;
    height: 122px;
    border-radius: 4px;
    color: #333;
    margin-bottom: 30px;
    border: 1px solid #f0f0f0;
    transition: all 0.3s;
    // cursor: pointer;
    > a {
        padding: 20px 25px;
        color: #333;
        display: block;
        width: 100%;
        height: 100%;
        &:hover {
            color: #fff;
        }
    }

    > div {
        width: 100%;
    }

    .size-20 {
        line-height: 1;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background-color: @primary-color;
        color: #fff;
    }

    .home-service-a {
        cursor: pointer !important;
        background-color: #2eaaf9;
        width: 40px;
        height: 18px;
        line-height: 12px;
        font-size: 14px;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.home-service-title {
    position: relative;
    overflow: hidden;
    padding: 15px 20px;

    a {
        height: 19px;
        width: 19px;
        text-align: center;
        display: block;
        border: solid 1px #d2d2d2;
        line-height: 11px;

        i {
            font-size: 14px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        height: 16px;
        width: 4px;
        background-color: #1890ff;
        border-radius: 15px;
        left: 4px;
        top: 24px;
    }
}

.swiper-home-politique {
    height: 420px;

    .swiper-slide {
        position: relative;
        width: auto;
        border-bottom: solid 1px #e8e8e8;
        // cursor: pointer;
        transition: all 0.3s;
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            h4 {
                color: @primary-color;
            }
        }

        .ant-row {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translate(0, -50%);
        }

        .ant-tag {
            transition: none;
            height: 26px;
            line-height: 26px;
            font-size: 14px;
            color: #0e0e0e;
            border: navajowhite;
            width: auto !important;
            background-color: rgba(229, 229, 229, 1);
            &.ant-tag-has-color {
                color: #fff;
            }
        }
    }
}

.home-date-box {
    border-radius: 5px;
    background-color: #fafafa;
    width: 73px;
    height: 73px;
    text-align: center;

    > div {
        height: 100%;
    }

    .day {
        color: #000;
        line-height: 1.1;
    }
}

.home-park {
    .ant-list-split {
        .ant-list-items {
            a {
                &:last-child {
                    .ant-list-item {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .ant-list-item {
            padding: 16px;
            border-bottom: 0;
            cursor: pointer;
            margin-bottom: 35px;
            border-radius: 4px;

            .ant-typography {
                line-height: 28px;
                height: 86px;
            }

            .ant-list-item-meta-title {
                font-size: 22px;
                line-height: 25px;
                height: 50px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-bottom: 0;
            }

            &:hover {
                .ant-list-item-meta-title {
                    color: @primary-color !important;
                }
            }
        }
    }
}
.home-dynamic-icon {
    line-height: 14px;
    i {
        vertical-align: bottom;
    }
}
.enterprises-list {
    position: relative;
    text-align: center;
    height: 245px;
}

.enterprises-list-row {
    display: inline-block;
    vertical-align: middle;
    width: 165px;
    text-align: center;
    transition: all 0.3s;
    height: 215px;
    padding-top: 12px;

    &:hover {
        padding: 0;

        i {
            background-color: @primary-color;
            color: #fff;
            height: 160px;
            width: 160px;
        }
        span {
            margin-top: 10px;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    i {
        position: relative;
        display: inline-block;
        width: 120px;
        height: 120px;
        background-color: #fafafa;
        border-radius: 50%;
        font-size: 40px;
        color: #333;
        transition: all 0.3s;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    span {
        display: block;
        line-height: 21px;
        font-size: 22px;
        margin-top: 30px;
        color: #000;
        transition: all 0.3s;

        &::before {
            content: "";
            position: relative;
            top: -5px;
            margin: 0 auto;
            width: 10px;
            height: 2px;
            background-color: @primary-color;
            display: none;
        }
    }
}
.notice-title {
    position: relative;
    height: 64px;
    line-height: 64px;
    width: 125px;
    color: #010101;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        height: 24px;
        width: 1px;
        background-color: #8c8c8c;
        transform: translate(-50%, -50%);
    }
}
.home-modal {
    .ant-modal-content {
        height: 100%;
        .ant-modal-body {
            height: calc(~"100% - 108px");
            overflow: auto;
            &::-webkit-scrollbar {
                width: 9px;
                height: 9px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
            }
            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
}
@media (max-width: 1366px) {
    .home-box {
        zoom: 0.8;
    }
}

.a-title {
    color: black;
    font-size: 18px;

    &:hover {
        color: @primary-color;
    }
}

/*首页 -- end*/

.date-color{
    color:#c0c1db;
}
.title-dot{
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #6d74ff;
    vertical-align: 1px;
}
.dynamic-list{
    &.ant-list-lg .ant-list-item{
        padding: 24px 0;
        cursor: pointer;
        &:hover{
            .dynamic-list-title, .date-color{
                color:#3b27ff !important;
            }
        }
        &.active{
            .dynamic-list-title, .date-color{
                color:#3b27ff !important;
            }
        }
    }
}
.custom-more{
    display: inline-block;
    width: 194px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border:#b1a9ff solid 1px;
    border-radius: 18px;
    color:@primary-color;
}
.overview-content{
    background: url("../images/baod/gl-bg.jpg") no-repeat center;
    background-size: cover;
    height: 650px;
    padding: 30px 0;
}
.line-dot{
    display: inline-block;
    width: 24px;
    height: 6px;
    background-color: #5f4fff;
    vertical-align: 8px;
}
.overview-navigation{
    position: relative;
    height: 48px;
    width: 100px;
    span{
        display: inline-block;
        width: 48px;
        height: 48px;
        background-color: @white-color;
        text-align: center;
        color:@gray-one-color;
        &:after{
            font-size: 24px;
            line-height: 48px;
        }
        &.swiper-button-prev{
            left: 0;
        }
        &.swiper-button-next{
            right: 0;
        }
        &:hover{
            background-color: #6d74ff;
            color:@white-color;
        }
    }
}
.overview-pagination{
    position: relative;
    .swiper-pagination{
        position: static;
        text-align: left;
        font-size: 26px;
        color:rgba(255,255,255,0.6);
        .swiper-pagination-current{
            color:#7d83ff;
            font-size: 32px;
        }
    }
}
.overview-container{
    width: 1660px;
    margin: 0 0 0 auto;
}
.impression-box{
    position: relative;
    height: 450px;
    .impression-swiper-bottom{
        position: absolute;
        right:10px;
        bottom:10px;
        z-index: 10;
        width: 50%;
        .swiper-wrapper{
            justify-content: flex-end;
        }
        div.images-auto{
            border:#c9c9c9 solid 2px;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                left: 0;
                top:0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.3);
                z-index: 10;
            }
        }
        .swiper-slide-thumb-active{
            div.images-auto{
                border-color:#3b27ff;
                &:after{
                    display: none;
                }
            }
        }
    }
    .impression-navigation{
        position: absolute;
        left: 32%;
        top:75px;
        height: 32px;
        width: 75px;
        span{
            display: inline-block;
            width: 32px;
            height: 32px;
            background-color: @white-color;
            text-align: center;
            line-height: 32px;
            box-shadow: 0 2px 8px 0 rgba(11, 19, 186, 0.19);
            border-radius: 50%;
            color:@gray-one-color;
            &:after{
                font-size: 12px;
            }
            &:hover{
                background-color: #6d74ff;
                color:@white-color;
            }
        }
        .swiper-button-prev{
            left: 0;
        }
        .swiper-button-next{
            right: 0;
        }
    }
}
.more-arrow{
    vertical-align: 2px;
}
.company-content{
    background: url("../images/baod/zy-bg.jpg") no-repeat center;
    background-size: cover;
    height: 720px;
    padding: 90px 0;
}
.company-nav{
    max-width: 260px;
    .company-nav-item{
        height: 106px;
        padding: 0 0 0 30px;
        display: flex;
        align-items: center;
        color:rgba(255,255,255,0.5);
        font-size: 22px;
        position: relative;
        cursor: pointer;
        .icon{
            font-size: 22px;
            margin-right: 30px;
            display: inline-block;
            width: 30px;
            text-align: center;
        }
        &:before{
            content:"";
            position: absolute;
            top:0;
            left: 0;
            height: 1px;
            width: 100%;
            background: linear-gradient(to right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 80%);
        }
        &:last-child{
            &:after{
                content:"";
                position: absolute;
                bottom:0;
                left: 0;
                height: 1px;
                width: 100%;
                background: linear-gradient(to right, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 80%);
            }
        }
        &.active{
            background-color: rgba(255,255,255,0.1);
        }
    }
}
.custom-color1{
    color:#8599ff !important;
}
.custom-color2{
    color:#6d74ff !important;
}
.custom-color3{
    color:#cfd4e9 !important;
}
.custom-color4{
    color:#1dc95e !important;
}
.custom-color5{
    color:#ff8400 !important;
}
.custom-color6{
    color:#3355d3 !important;
}
.company-item{
    height: 400px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    .company-write{
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
    }
    &:after{
        content:"";
        position: absolute;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        background-color: rgba(27,31,130,0.85);
        z-index: 2;
    }
}
.company-icon{
    font-size: 62px !important;
}
.activity-line{
    padding-left: 15px;
    position: relative;
    &:before{
        content:"";
        position: absolute;
        left: 0;
        top:50%;
        transform: translateY(-50%);
        width: 2px;
        height: 40px;
        background-color: #5255d9;
    }
}
.activity-img{
    position: relative;
    div.images-auto{
        display: block;
    }
    span{
        position: absolute;
        width: 56px;
        height: 56px;
        right:-10px;
        bottom:-10px;
        text-align: center;
        line-height: 56px;
        color:@white-color;
        &.type1{
            background-color: #5255d9;
        }
        &.type2{
            background-color: #5e86cf;
        }
        &.type3{
            background-color: #ddd;
            color:@gray-color;
        }
    }
}
.activity-swiper{
    .swiper-pagination-bullet{
        transition: all 0.25s;
        margin: 0 3px !important;
    }
    .swiper-pagination-bullet-active{
        width: 24px;
        background-color: #6d74ff;
        border-radius: 4px;
    }
}
.activity-content{
    background-color: #f2f3f9;
    margin-top: 0;
    padding: 40px 0;
}
.school-swiper.swiper-container{
    padding: 15px;
}
.school-item{
    background-color: @white-color;
    box-shadow: 0 8px 16px 0 rgba(82, 85, 217, 0.2);
    height: 400px;
    padding: 20px;
}
.school-img{
    text-align: center;
    width: 160px;
    height: 160px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
}
.school-swiper{
    .swiper-pagination-bullet{
        transition: all 0.25s;
        margin: 0 3px !important;
    }
    .swiper-pagination-bullet-active{
        width: 24px;
        background-color: #6d74ff;
        border-radius: 4px;
    }
}
.bold{
    font-weight: bold;
}

@media (max-width: 1660px) {
    .overview-content{
        height: 580px;
    }
    .overview-container{
        width: 1200px;
    }
    .overview-swiper{
        .swiper-slide{
            div.images-auto{
                height: 240px !important;
            }
        }
    }
}
@media (max-width: 1400px) {
    .home-body .home-text-content{
        max-width: 1200px;
    }
}
