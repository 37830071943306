
.meeting-root{
  height: 100%;
  body {
    overflow: hidden;
    min-width: 0;
    width: 1920px;
    height: 1080px;
    transform-origin: left top;
    position: relative;
    left: 50%;
    top:50%;
    background: url("../images/meeting/meet-bg2.png") no-repeat;
    background-size: cover;
  }
  .root{
    height: 100%;
    width: 100%;
  }
  .mask-box{
    width: 46.35%;
    position: relative;
    .mask-img{
      position: absolute;
      left: 0;
      top:0;
      z-index: 10;
      width: 100%;
      height: 100%;
    }
  }
  .meeting-box{
    display: flex;
    width: 100%;
    &.type2{
      background: url("../images/meeting/meet-bg1.png") no-repeat center;
      background-size: cover;
      display: block;
      padding: 0 56px;
      .meeting-time{
        color:@white-color;
      }
      .fm-title{
        font-size: 180px;
        color:@white-color;
        margin-top: -70px;
        //text-shadow: 10px 10px 5px rgba(0,0,0,0.8);
        &.small{
          margin-top: 60px;
          text-shadow: none;
          font-size: 124px;
        }
      }
    }
  }
  .meeting-content{
    width: 53.65%;
  }
  .meeting-time{
    color:#3d4246;
    line-height: 1;
    width: 210px;
    text-align: center;
    .time{
      font-size: 52px;
    }
    .date{
      font-size: 22px;
      margin-top: 10px;
    }
  }
  .meeting-code{
    width: 195px;
    display: flex;
    align-items: center;
    .meeting-code-name{
      font-size: 24px;
      color:#3d4246;
    }
    .meeting-code-img{
      img{
        width: 156px;
      }
    }
  }
  .fm-title{
    font-size: 100px;
    color:#3d4246;
    text-align: center;
    margin-top: 200px;
    &.small{
      margin-top: 20px;
      font-size: 70px;
    }
  }
  .meeting-swiper-content{
    background-color: #fde9e9;
    border-radius: 20px;
    padding:40px 30px;
    width: 820px;
    margin: 60px auto 0;
    font-size: 36px;
    color:#3d4246;
    .metting-swiper-label{
      color: #565b5e;
    }
  }
}
.meet-swiper-header{
  font-size: 38px;
  color:@white-color;
  text-align: center;
  margin-top: 100px;
}
.meet-vertical-swiper{
  .swiper-slide{
    color:@white-color;
  }
  .meet-swiper-item{
    font-size: 34px;
  }
}
.meeting-swiper-div{
  width: 1300px;
  margin: 0 auto;
}
.right-meeting-code{
  position: absolute;
  right:30px;
  bottom:20px;
  z-index: 2;
}
.slide-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.meet-swiper-hidden{
  overflow: hidden;
}
