
/* 字体*/
@font-face {
    font-family: "PingFang";
    src: url('../css/font/PingFang Medium.ttf') format('truetype');
}
.pf-title{
    font-family: PingFang !important;
    color: #262626;
}



.descriptions-policy {
    .ant-descriptions-item-label {
        color: #666666;
    }
    .ant-descriptions-row > th, .ant-descriptions-row > td{
        padding-bottom: 6px;
    }
}

.policy-card-list {
    .ant-card {
        margin-bottom: 15px;
        .ant-card-body {
            padding: 18px 0 18px 18px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.not-border {
    border: none !important;
}
.policy-tag-box{
    width: 150px;
}
.policy-tag {
    height: 69px;
    width: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #ffffff;
    font-size: 16px;
    margin: 0 auto;
    &.bg-red {
        background: linear-gradient(0deg, #cf1f25 0%, #f8726d 100%);

    }
    &.bg-yellow {
        background: linear-gradient(0deg, #ff9933 0%, #ffab57 100%) !important;
    }
    &.bg-grey{
        background: linear-gradient(0deg, #969696 0%, #c2c2c2 100%);
    }
}

.policy-card {
    .ant-card-head {
        background: rgba(24, 144, 255, 0.04);
    }
    .ant-card-head-title {
        font-size: 18px;
        line-height: 1;
        font-weight: 500;
        color: #333333;
    }
}
.policy-list {
    padding: 0;
    li {
        display: flex;
        margin-bottom: 26px;
        font-size: 16px;
        color: #333333;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            .key {
                color: #1890ff;
            }
            .item {
                font-weight: 500;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
        .key {
            position: relative;
            display: block;
            width: 14px;
            box-sizing: border-box;
            font-size: 22px;
            font-family: SFProText;
            font-weight: normal;
            color: #b8b8b8;
            text-align: center;
            font-style: italic;
            padding-left: 3px;
            &:after {
                content: "";
                position: absolute;
                width: 23px;
                height: 23px;
                background: #1890ff;
                opacity: 0.08;
                border-radius: 8px;
                top: 11px;
                left: 0;
            }
        }
        .item {
            padding-left: 20px;
            font-weight: 400;
            display: flex;
            align-items: center;
        }
    }
}
.policy-details-card {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
    .ant-card-body {
        padding: 30px;
    }
}

.interval {
    height: 3px;
    background: #edf6ff;
    margin: 15px -15px;
}
.policy-tabs {
    display: flex;
    .item {
        width: 236px;
        height: 54px;
        font-size: 20px;
        font-weight: 500;
        color: #999999;
        background: #ffffff;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 3px;
            background: #1890ff;
            transition: 0.3s all;
        }
        &.active,
        &:hover {
            border: 1px solid #d1e9ff;
            color: #1890ff;
            &:after {
                width: 100%;
            }
        }
    }
}

.policy-circle-list {
    &.small {
        padding-top: 24px;
        .item {
            padding: 20px 60px 20px 60px;
            margin-top: 30px;
        }
    }
    .item {
        position: relative;
        background: #ffffff;
        border-radius: 8px;
        padding: 30px 40px 20px 40px;
        margin-top: 54px;
        box-shadow: 0 0 7px 0 rgba(34, 161, 255, 0.2);
        .extra {
            i {
                color: #c7e4ff;
                font-size: 30px;
            }
        }
        .title {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -24px;
            padding: 0 24px;
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            height: 38px;
            background: #6ba3e0;
            border-radius: 24px;
            left: 35px;
        }
        .matter {
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.policy-step {
    margin: 0 -10px;
    position: relative;
    overflow: hidden;
    .item {
        position: relative;
        width: 395px;
        height: 100px;
        float: left;
        display: flex;
        align-items: center;
        padding-left: 90px;
        &.on {
            .figure,
            .title,
            .desc {
                color: #ffffff;
            }
        }
        .figure {
            font-size: 76px;
            font-family: SFProText;
            color: #cccccc;
            font-weight: bold;
            font-style: italic;
            line-height: 1;
            margin-right: 26px;
        }
        .title {
            font-size: 20px;
            font-weight: 500;
            color: #999999;
            line-height: 30px;
        }
        .desc {
            font-size: 16px;
            font-weight: 500;
            color: #999999;
            line-height: 30px;
        }
        &:nth-child(1) {
            background: url("../images/policy/step_1.png") no-repeat;
            background-size: cover;
            &.on {
                background: url("../images/policy/step_1_on.png") no-repeat -22px -4px;
                background-size: 106% 112%;
            }
        }
        &:nth-child(2) {
            background: url("../images/policy/step_2.png") no-repeat;
            margin-left: -15px;
            background-size: cover;
            &.on {
                background: url("../images/policy/step_2_on.png") no-repeat -3px -4px;
                background-size: 102% 112%;
            }
        }
        &:nth-child(3) {
            background: url("../images/policy/step_3.png") no-repeat;
            background-size: cover;
            margin-left: -15px;
            &.on {
                background: url("../images/policy/step_3_on.png") no-repeat -3px -4px;
                background-size: 102% 112%;
            }
        }
    }
}
.policy-form {
    padding: 24px 0;
    .ant-form-item {
        margin-bottom: 24px;
    }
    .avatar-uploader {
        width: auto;
    }
    .title {
        position: relative;
        font-size: 24px;
        font-weight: bold;
        color: @primary-color;
        padding: 10px 0;
        text-align: center;
        margin-bottom: 24px;
        &.left {
            text-align: left;
            padding-left: 12px;
            &:after {
                left: 0;
                transform: none;
            }
        }
        &:after {
            content: "";
            position: absolute;
            width: 128px;
            height: 4px;
            background: linear-gradient(0deg, #50abff 0%, @primary-color 100%);
            border-radius: 2px;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, -50%);
        }
    }
    .ant-form-item-label {
        & > label {
            font-size: 18px;
        }
    }
    .ant-select-selector,
    .ant-input {
        // background-color: #f7f7f7 !important;
        border: 1px solid #ededed !important;
    }
}

.policy-collapse {
    &.ant-collapse-borderless {
        background-color: transparent;
        .ant-collapse-header {
            font-size: 24px;
            color: @primary-color !important;
            &:after {
                content: "";
                position: absolute;
                width: 6px;
                height: 26px;
                background: #1890ff;
                border-radius: 3px;
                left: 18px;
                top: 17px;
            }
            .anticon {
                display: none !important;
            }
        }
        & > .ant-collapse-item {
            border-bottom: none;
            &.ant-collapse-item-active {
                .ant-collapse-header {
                    .icon {
                        &:before {
                            content: "\e714";
                        }
                    }
                }
            }
        }
    }
}
.textArea-box{
    textarea{
        border-width: 1px;
        border-color: rgb(237, 237, 237);
        border-style: solid;
        border-radius: 10px;
        background-color: rgb(247, 250, 252);
    }
}
